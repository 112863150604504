.input-field {
	appearance: none;
  border: none;
	border-bottom: 0.1rem $c-mischka solid;
	border-radius: 0;
	min-height: 3.4rem;
	font-size: 1.6rem;
  padding: 1rem 0;
	font-family: $f-primary;
	&:focus {
		@extend %default-form-element-focus-state;
	}
	&--block {
		display: block;
		width: 100%;
  }
  &--error {
    background: rgba($c-error, 0.05);
    border-color: $c-error;
  }
}
