.submit {
  border: none;
  background: none;
  appearance: none;
  font-family: $f-primary;
  font-size: 1.6rem;
  font-weight: 300;
  border-bottom: 1px black solid;
  padding: 1rem 0;
  cursor: pointer;
  border-radius: 0;

  &:hover,
  &:focus {
    border-bottom-color: $c-secondary;
  }
}