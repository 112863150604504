/* ----------------------------------------------------------------------------
 * Mixins
 * ------------------------------------------------------------------------- */

/**
*
* General items
* 
**/

// clearfix
%clearfix, .clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// Absolute element top-left-right-bottom 
%abs-full{
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

// Simulate "background-size: cover" on normal image
%bg-cover{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
// %bg-cover{
//   position: absolute;
//   top: -9999px;
//   right: -9999px;
//   bottom: -9999px;
//   left: -9999px;
//   margin: auto;
//   min-width: 100%;
//   min-height: 100%;
//   width: auto;
//   height: auto;
// }


.has-objectfit{
  %bg-cover{
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}

// Ellips text
// -- Width of the text needs to be defined on the element
%text-ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/**
*
* Responsive queries
* 
**/

// responsiveness
@mixin respond-to($media) {
  @if $media == xsmall {
    @media screen and (max-width: $bp-xsmall) {
      @content;
    }
  } @else if $media == small {
    @media screen and (max-width: $bp-small) {
      @content;
    }
  } @else if $media == medium {
    @media screen and (max-width: $bp-medium) {
      @content;
    }
  } @else if $media == medium-up {
    @media screen and (min-width: $bp-small + 0.1) {
      @content;
    }
  } @else if $media == large {
    @media screen and (max-width: $bp-large) {
      @content;
    }
  } @else if $media == xlarge {
    @media screen and (min-width: $bp-large + 0.1) {
      @content;
    }
  } @else if $media == jump-narrow {
    @media screen and (max-width: $breakpoint-jump-narrow) {
      @content;
    }
  } @else if $media == screenreader {
    @media reader, speech, aural {
      @content;
    }
  } @else {
    @content;
  }
}

#hidden, .hidden {
  display: none !important;
}

@mixin gradient ($startColor: #eee, $endColor: white) {
	background: $startColor;
	background: -webkit-gradient(linear, left top, left bottom, from($startColor), to($endColor));
	background: -webkit-linear-gradient(top, $startColor, $endColor);
	background: -moz-linear-gradient(top, $startColor, $endColor);
	background: -ms-linear-gradient(top, $startColor, $endColor);
  background: -o-linear-gradient(top, $startColor, $endColor);
  background: linear-gradient(to top, $startColor, $endColor);
}

@mixin border-radius($radius) {
	border-radius: $radius;
	-moz-border-radius: $radius;
	-webkit-border-radius: $radius;
}

@mixin border-radiuses ($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
  -moz-border-radius: $topleft $topright $bottomright $bottomleft;
  -webkit-border-radius: $topleft $topright $bottomright $bottomleft;
  border-radius: $topleft $topright $bottomright $bottomleft;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

@mixin box-sizing ($type: border-box) {
  box-sizing:$type;
  -moz-box-sizing:$type;
	-webkit-box-sizing:$type;	
}

@mixin clearfix() {
  zoom: 1;
  &:before { content: ''; display: block; }
  &:after { content: ''; display: table; clear: both; }
}

@mixin drop-shadow ($x: 0, $y: 1px, $blur: 2px, $spread: 0, $alpha: 0.25) {
	-webkit-box-shadow:	$x $y $blur $spread rgba(0, 0, 0, $alpha);
	-moz-box-shadow:	$x $y $blur $spread rgba(0, 0, 0, $alpha);
	box-shadow:		$x $y $blur $spread rgba(0, 0, 0, $alpha);
}

@mixin text-icon($position: left center) {
	background-repeat:no-repeat;
	background-position:$position;
}

@mixin ellipse-textoverflow() {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin header-type($fontSize, $color, $fontWeight, $lineHeight) {
  font-size: $fontSize;
  color: $color;
  font-weight: $fontWeight;
  line-height: $lineHeight;
}

@mixin grey-links{
  a { 
      color:#252525; 
      text-decoration:underline; 
      cursor: pointer;

      &:visited { 
          color:#252525; 
      } 
      &:focus { 
          outline:thin dotted; 
      }
      &:hover, &:active, &:focus { 
          color:#808080; 
          text-decoration:none; 
      }
  }
}

@mixin vertical-gradient($fallbackColor, $topColor, $bottomColor) {
	background:$fallbackColor;
  background:-webkit-gradient(linear, 0 0, 0 100%, from($topColor) to($bottomColor)); /*old webkit*/
  background:-webkit-linear-gradient($topColor, $bottomColor); /*new webkit*/
  background:-moz-linear-gradient($topColor, $bottomColor); /*gecko*/
  background:-ms-linear-gradient($topColor, $bottomColor); /*IE10*/
  background:-o-linear-gradient($topColor, $bottomColor); /*opera 11.10+*/
  background:linear-gradient($topColor, $bottomColor); /*future CSS3 browsers*/
  //-pie-background:linear-gradient($topColor, $bottomColor); /*PIE*/
	//behavior: url(/sites/all/themes/amoras/css/PIE.htc);
}

@mixin muted {
  color: $grey;
}

@mixin large-header($color: $if-blue) {
  font-size: $h1-size;
  color: $color;
  font-weight: 400;
  line-height: $title-line-height;
}

@mixin small-header($color: $black) {
  font-size: $xl-font;
  color: $color;
  font-weight: 400;
  line-height: $title-line-height;
}

@mixin opacity($opacity: 0.5) {
  -moz-opacity: $opacity;
  -khtml-opacity: $opacity;
  -webkit-opacity: $opacity;
  opacity: $opacity;
  $opperc: $opacity * 100;
   -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=#{$opperc})";
  filter: "alpha(opacity=#{$opperc})";
}

@mixin transition($property: background, $duration: 1s) {
	-webkit-transition:$property $duration ease;
	-moz-transition:$property $duration ease;
	-o-transition:$property $duration ease;
	transition:$property $duration ease;
}

@mixin enableHWAccel() {
	-webkit-transform: translate3d(0,0,0);
	-moz-transform: translate3d(0,0,0);
	-ms-transform: translate3d(0,0,0);
	-o-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}
