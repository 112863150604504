.navigation {
  &__toggle {
    cursor: pointer;
    &:hover &,
    &:focus & {
      &__stroke {
        background: $c-secondary;
      }
    }
    &__stroke {
      display: block;
      margin-bottom: .6rem;
      width: 2rem;
      height: .1rem;
      background: rgba(black, .7);
      transition: all .2s ease-in-out;
    }
  }
}