@import "iconfont/iconfont";

.typography {
	a {
		color: $c-primary;
	}

	p {
		font-size: 1.4rem;
    font-weight: 300;
    color: $c-text;
    line-height: 1.6;
    &:not(:last-child) {
    	margin-bottom: 1.5rem;
    }
	}
	
	.h2, .h3 {
		margin-bottom: 1.5rem;
	}

	&--small {
		p {
			font-size: 1.2rem;
		}
	}
}