/* ----------------------------------------------------------------------------
 * Structure
 * -- Define structural reset of certain items
 * ------------------------------------------------------------------------- */

// Global greset
* {
  margin: 0;
  padding: 0;
  outline: 0;
}

// global box-sizing
* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  &:before, &:after {
    box-sizing: inherit;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }
}

html {
    font-size: 62.5%;
    height:100%;
    min-height:100%;
}

body {
    height: 100%;
    min-height:100%;

    font-weight: 400;
    font-family: $f-primary;
    font-size: 1.3rem;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: white;
}

.site-wrapper {
  // overflow: hidden;
  // display: flex;
  // width: 100%;
  // height: 100%;
}

