/* ----------------------------------------------------------------------------
 * Fonts
 * ------------------------------------------------------------------------- */

$f-primary: 'Muli', 'helvetica', sans-serif;

$f-icon: 'lenb_iconfont';

/* ----------------------------------------------------------------------------
 * General variables
 * ------------------------------------------------------------------------- */

// Namespace
$ns: '' !default;

// Breakpoints
$bp-xsmall: 500px;
$bp-small: 767px;
$bp-medium: 1023px;
$bp-large: 1600px; // Default
// $bp-xlarge: everything above large
$bp-list: (
	l: (media: 'large'),
	m: (media: 'medium'),
	s: (media: 'small'),
	xs: (media: 'xsmall')
);

// page background
$page-bg: #fff;
$alt-region-bg: #dedede;

// Page width
$page-min-width: 768px;
$page-max-width: 1024px;
$page-max-width-wide: 1280px;
$box-max-width: 1600px;
$page-padding: 30px;
$breakpoint: 1023px;
$breakpoint-jump-narrow: 1350px;

// Mobile
//$mobileRule: max-device-width;
$mobileRuleInverse: min-device-width;
$mobileRule: max-width;
$base-mobile-font-size: 1.5rem;
$base-mobile-line-size: 1.33;

// Grid settings
$grd-total-width: 100%;
$grd-gutter-width: 3%;
$grd-border-gutters: 1; //1 of 0
$grd-debug: 0;
$grd-debug-colors: 0;

$grda-columns: 6;
$grdb-columns: 4;

/* ----------------------------------------------------------------------------
 * Colors
 * ------------------------------------------------------------------------- */

$c-primary: #3B603B; // Blue color
$c-primary-hover: darken(#2b78a7, 5%); // Blue color
$c-secondary: #D07A2F;
$c-tertiary: #eb902d; // Orange color
$c-background: #fbfcfd; // Light blue color
$c-highlight: #42a0da; // Blue color - bright
$c-text: #333; // Blue color - dark
$c-dove-blue: #787887; // Blue color - smeeh
$c-icon-blue: #d6e0e8; // Blue color - icons
$c-athens-gray: #f4f6f8; // Light grey
$c-french-gray: #c6c6cc; // Little darker
$c-dolphin: #69697a;
$c-manatee: #989ca5; // Medium dark grey
$c-orange: #f68c00; //orange
$c-mischka: #dbdbe1; // Grey with a touch of blue
$c-border-light: #D8D8D8; // Light border
$c-modal-background: #e6e9ed; // Modal background color

$c-unread: #ec4f60;

$c-black: #333;

$c-success: #7ed321;
$c-warning: #eb902d;
$c-error: #ec4f60;

/* ----------------------------------------------------------------------------
 * Spacing
 * ------------------------------------------------------------------------- */

$spacing-tiny: 1rem;
$spacing-small: 2rem;
$spacing-normal: 3rem;
$spacing-large: 6rem;

/* ----------------------------------------------------------------------------
 * Sidebar
 * ------------------------------------------------------------------------- */
$sidebar-width: 26rem;
$sidebar-width--medium: 22rem;

/* ----------------------------------------------------------------------------
 * Navigation
 * ------------------------------------------------------------------------- */
$navigation-width: 22rem;
$navigation-width--collapsed: 6.2rem;

/* ----------------------------------------------------------------------------
 * Banner
 * ------------------------------------------------------------------------- */
$banner-row-default-height: 6rem;
$banner-row-user-height: 8rem;
$banner-large-height: $banner-row-default-height + $banner-row-user-height;
