.textarea {
	appearance: none;
	border: none;
	border-bottom: 0.1rem $c-mischka solid;
	border-radius: 0;
	background: white;
	padding: 1rem 0;
	font-size: 1.4rem;
	font-family: $f-primary;
	resize: vertical;
	&:focus {
		@extend %default-form-element-focus-state;
	}
	&--block {
		display: block;
		width: 100%;
	}

	::-webkit-input-placeholder {
	  color: $c-french-gray;
	}
	::-moz-placeholder {
	  color: $c-french-gray;
	}
	:-ms-input-placeholder {
	  color: $c-french-gray;
	}
	:-moz-placeholder {
	  color: $c-french-gray;
	}
}