
.region {
  margin:0 auto;
  padding: 3rem 0 6rem;
  clear: both;
  @extend %clearfix;

  @include respond-to(small) {
    padding: 3rem 0 3rem;
  }
}



.region:not(.region--alt) + .region:not(.region--alt) {
  padding-top: 0;
}

// color variants
.region--alt {
  background-color: $alt-region-bg;
  //  padding-bottom: 3rem;
}

// two consequent different regions
.region:not(.region--alt) + .region--alt, .region--alt + .region:not(.region--alt) {
  //  margin-top: 5rem;
  padding-top: 6rem;

  @include respond-to(small) {
    //    margin-top: 3rem;
    padding-top: 3rem;
  }
}

.region:first-child {
  padding-top: 6rem;
  @include respond-to(small) {
    padding-top: 2rem;
  }
}

.region--no-space {
  padding: 0 !important;
}

.region--no-space-bottom {
  padding-bottom: 0 !important;
}

.region--no-space-top {
  padding-top: 0 !important;
}


.region--small {
  padding: 3rem 0 !important;

  @include respond-to(small) {
    padding: 2rem 0 !important;
  }
}

.region--flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .grid {
    width: 100%;
  }
}

.region--full-height {
  height: 100vh;
}

.region--medium {
  padding: 4rem 0 !important;

  @include respond-to(small) {
    padding: 2.5rem 0 !important;
  }
}

.region--bordered + .region--bordered {
  border-top: 1px solid $alt-region-bg;

  &.region--alt {
    border-top-color: #fff;
  }
}
