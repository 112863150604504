// Positioning
//
// Positioning utilities
//
// Markup: positioning.html
//
// Styleguide utilities.positioning

// Positioning

.u-float-right {
  float: right !important;
}

.u-float-left {
  float: left !important;
}

.u-float-none {
  float: none !important;
}

.u-display-inline-block {
  display: inline-block !important;
}

.u-clearfix {
  @extend %clearfix;
}

.u-no-overflow {
  overflow: hidden;
}