@font-face {
	font-family: "epo_iconfont";
	src: url('/fonts/iconfont/epo_iconfont.eot');
	src: url('/fonts/iconfont/epo_iconfont.eot?#iefix') format('eot'),
		url('/fonts/iconfont/epo_iconfont.woff') format('woff'),
		url('/fonts/iconfont/epo_iconfont.ttf') format('truetype'),
		url('/fonts/iconfont/epo_iconfont.svg#epo_iconfont') format('svg');
}

.epoi:before {
	font-family: "epo_iconfont";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}


.epoi-admin-user:before {
	content: "\E001";
}

.epoi-align:before {
	content: "\E002";
}

.epoi-arrow-left-barred:before {
	content: "\E003";
}

.epoi-arrow-left-fat:before {
	content: "\E004";
}

.epoi-arrow-right-fat:before {
	content: "\E005";
}

.epoi-arrow-right-large:before {
	content: "\E006";
}

.epoi-arrow-right:before {
	content: "\E007";
}

.epoi-bell:before {
	content: "\E008";
}

.epoi-book:before {
	content: "\E009";
}

.epoi-cake:before {
	content: "\E00A";
}

.epoi-caret-down:before {
	content: "\E00B";
}

.epoi-caret-right:before {
	content: "\E00C";
}

.epoi-caret-up:before {
	content: "\E00D";
}

.epoi-check:before {
	content: "\E00E";
}

.epoi-close-fat:before {
	content: "\E00F";
}

.epoi-close-outline:before {
	content: "\E010";
}

.epoi-close:before {
	content: "\E011";
}

.epoi-dashboard:before {
	content: "\E012";
}

.epoi-diagram:before {
	content: "\E013";
}

.epoi-document-money:before {
	content: "\E014";
}

.epoi-education:before {
	content: "\E015";
}

.epoi-fat-arrow-right:before {
	content: "\E016";
}

.epoi-file:before {
	content: "\E017";
}

.epoi-graph:before {
	content: "\E018";
}

.epoi-ico-briefcase:before {
	content: "\E019";
}

.epoi-ico-dashboard:before {
	content: "\E01A";
}

.epoi-ico-functiebeschrijvingen:before {
	content: "\E01B";
}

.epoi-ico-onkostennota:before {
	content: "\E01C";
}

.epoi-ico-opleiding:before {
	content: "\E01D";
}

.epoi-ico-organogram:before {
	content: "\E01E";
}

.epoi-ico-performance-management:before {
	content: "\E01F";
}

.epoi-ico-rapporten:before {
	content: "\E020";
}

.epoi-ico-stars:before {
	content: "\E021";
}

.epoi-ico-team:before {
	content: "\E022";
}

.epoi-ico-vacatures:before {
	content: "\E023";
}

.epoi-info:before {
	content: "\E024";
}

.epoi-logout:before {
	content: "\E025";
}

.epoi-organogram:before {
	content: "\E026";
}

.epoi-padlock:before {
	content: "\E027";
}

.epoi-plus-alt:before {
	content: "\E028";
}

.epoi-plus-fat:before {
	content: "\E029";
}

.epoi-plus:before {
	content: "\E02A";
}

.epoi-presentation:before {
	content: "\E02B";
}

.epoi-report:before {
	content: "\E02C";
}

.epoi-search:before {
	content: "\E02D";
}

.epoi-settings:before {
	content: "\E02E";
}

.epoi-smiley-negative:before {
	content: "\E02F";
}

.epoi-smiley-neutral:before {
	content: "\E030";
}

.epoi-smiley-positive:before {
	content: "\E031";
}

.epoi-sort:before {
	content: "\E032";
}

.epoi-speech-bulb:before {
	content: "\E033";
}

.epoi-switch:before {
	content: "\E034";
}

.epoi-system-controller:before {
	content: "\E035";
}

.epoi-target:before {
	content: "\E036";
}

.epoi-team:before {
	content: "\E037";
}

.epoi-trash:before {
	content: "\E038";
}

.epoi-user-male:before {
	content: "\E039";
}

.epoi-user:before {
	content: "\E03A";
}

.epoi-vacation:before {
	content: "\E03B";
}

.epoi-warning:before {
	content: "\E03C";
}
