// Frame
//
// We have a fully prepared frame to use to quickly start developing. This can be downloaded <a href="/public/frame.html" download>here</a>.
// This template also includes links to header images which can be used as fallback.
//
//
// Styleguide frame

// framed page
.pageBox {
  width:100%;
  max-width:$box-max-width;
  min-height: 100vh;
  margin:0 auto;
  background: $page-bg;

  @include respond-to(xlarge){
    box-shadow:0 5px 7px 0 rgba(0, 0, 0, 0.35);
  }
}

// normal page
.page {
  width: 100%;
  //  min-height: 100vh;
  background-color: $page-bg;
}

.main-content {
  outline: none;
  position: relative;
}

@import "grid/settings";
@import "layout/layout";
@import "region/region";
