@mixin trans($args...) {
  transition: $args;
}

%anim0{
  @include trans(all 0s ease-in-out);
}

%anim1{
  @include trans(all .1s ease-in-out);
}

%anim1-5{
  @include trans(all .15s ease-in-out);
}

%anim2{
  @include trans(all .2s ease-in-out);
}

%anim3{
  @include trans(all .3s ease-in-out);
}

%anim4{
  @include trans(all .4s ease-in-out);
}

%anim5{
  @include trans(all .5s ease-in-out);
}
%anim10{
  @include trans(all 1s ease-in-out);
}

%anim15{
  @include trans(all 1.3s ease-in-out);
}

%opacityanim2{
  @include trans(opacity .2s ease-out);
  text-decoration: none;
  &:hover, &:focus{
    opacity: .65;
  }
}

%bounce {
  animation: bounce 1s linear;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100%  {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-12px);
  }
  60% {
    transform: translateY(-5px);
  }
  90% {
    transform: translateY(-2px);
  }
}

@keyframes fade-transition-from-left {
  0% {
    transform: translateX(-5px);
    opacity: 0;
    display: none;
  }
  1% {
    transform: translateX(-5px);
    opacity: 0;
    display: block;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}