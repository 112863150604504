// @import 'checkbox/checkbox';
// @import 'form-error/form-error';
// @import 'form-group/form-group';
// @import 'icon-toggle/icon-toggle';
@import 'input-field/input-field';
@import 'label/label';
// @import 'pill/pill';
// @import 'radio/radio';
// @import 'select/select';
@import 'submit/submit';
@import 'textarea/textarea';

%default-form-element-focus-state {
	outline: none;
	border-bottom: 0.1rem black solid;
}

%default-form-element-placeholder {
	::-webkit-input-placeholder {
		color: $c-french-gray;
	}
	::-moz-placeholder {
		color: $c-french-gray;
	}
	:-ms-input-placeholder {
		color: $c-french-gray;
	}
	:-moz-placeholder {
		color: $c-french-gray;
	}
}
