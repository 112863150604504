.subtitle {
  position: relative;
  font-weight: 100;
  font-size: 3rem;
  margin-bottom: 3rem;
  line-height: 1.4;
  @include respond-to(medium) {
    font-size: 3rem;
  }
  @include respond-to(medium) {
    font-size: 2.4rem;
  }
  &:before {
    content: '';
    position: absolute;
    left: -3.5rem;
    bottom: 1.1rem;
    width: 2.5rem;
    background: $c-secondary;
    height: .1rem;
  }
}