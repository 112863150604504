.u-color-tertiary {
	color: $c-tertiary;
}

.u-text-highlight {
  color: $c-orange;
}

.u-text-medium {
  font-weight: 500;
}
