/*
Normal layout variant
*/

.layout--normal {
  width:80%;
  min-width:$breakpoint+1;
  max-width:$page-max-width;

  // medium screens
  @include respond-to(medium) {
    width: auto;
    min-width:$page-min-width;
    max-width:$page-max-width;
  }
  // small screens
  @include respond-to(small) {
    width:auto;
    min-width:0;
  }
}
