.slide {
  position: relative;
  height: 100vh;
  left: calc(100vw - 55rem);
  width: 55rem;

  @include respond-to(medium) {
    left: calc(100vw - 48rem);
    width: 48rem;
  }
  @include respond-to(small) {
    left: 0;
    width: 100%;
    z-index: 6;
  }
  &__content {
    display: flex;
    flex-direction: column;
    &__header {
      position: fixed;
      width: 55rem;

      @include respond-to(medium) {
        left: calc(100vw - 48rem);
        width: 48rem;
      }
      @include respond-to(small) {
        left: 0;
        width: 100%;
      }

      top: 0;
      right: 0;
      background: white;
      z-index: 2;
      border-bottom: 1px $c-border-light solid;
      flex: 0 0 auto;
      padding: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      @include respond-to(xsmall) {
        padding: 1rem;
      }
      &__toggle {
        position: absolute;
        z-index: 2;
        top: 3.2rem;
        right: 3rem;
        appearance: none;
        border: none;
        background: none;
      }
    }
    &__body {
      flex: 1 0 100%;
      padding: 6rem;
      min-height: 100vh;
      display: flex;
      justify-content: center;
      flex-direction: column;
      @include respond-to(small) {
        min-height: 0;
      }
      @include respond-to(xsmall) {
        padding: 3rem;
      }
    }
    &__footer {
      flex: 0 0 auto;
      padding: 6rem;
      @include respond-to(xsmall) {
        padding: 3rem;
      }
    }
  }
}