.image-carousel {
  background: $c-primary;
  position: fixed;
  z-index: 5;
  height: 100vh;
  width: calc(100% - 55rem);
  @include respond-to(medium) {
    width: calc(100% - 48rem);
  }
  @include respond-to(small) {
    position: relative;
    width: 100%;
    height: 70vh;
  }
  &__item {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .flickity-prev-next-button {
    display: none;
  }

  .flickity-page-dots {
    position: absolute;
    right: 4rem;
    bottom: 4rem;
    text-align: right;
    z-index: 3;
    @include respond-to(small) {
      text-align: center;
      right: 0;
      left: 0;
    }

    .dot {
      display: inline-block;
      background: white;
      width: .6rem;
      height: .6rem;
    }
  }

  .flickity-slider {
    transform: none !important;
  }

  .gallery-cell {
    left: 0 !important;
    opacity: 0;
    transition: opacity 0.6s ease-in-out 0.3s; // Changed
    z-index: 1; // Changed
  }

  .gallery-cell.is-selected {
    opacity: 1;
    transition: opacity 0.6s ease-in-out; // Changed (Added)
    z-index: 2; // Changed
  }

  .flickity-prev-next-button {
    z-index: 3; // Changed
  }
}